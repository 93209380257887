<template>
  <div>
    <Modal v-model="showModal">
        <p slot="header">
            <span>作废</span>
        </p>
        <div class="p-l-10 p-r-10">
          <p class="p-t-10">请选择审批通道：</p>
      <RadioGroup v-model="sub">
        <Radio v-for="(item, i) in subs" :key="i" :label="item.subProcessType">{{ item.subProcessTypeName }}</Radio>
      </RadioGroup>
          <p>描述：</p>
          <i-input type="textarea" :rows="3" v-model="reason" placeholder="请输入作废理由"></i-input>
          <p class="p-t-5">上传附件</p>

          <Upload multiple type="drag" :before-upload="handleBeforeUploadFile" action>
            <div style="padding: 20px 0">
              <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
              <p>点击或拖拽文件到这里进行上传</p>
            </div>
          </Upload>
          <Row v-show="fileList.length">
            <i-col span="14">
              <b>文件名称</b>
            </i-col>
            <i-col span="5">
              <b>文件大小</b>
            </i-col>
            <i-col span="5">
              <b>操作</b>
            </i-col>
          </Row>
          <hr  v-show="fileList.length" />
          <Row v-for="(file,fileIndex) in fileList" :key="fileIndex" v-show="fileList.length">
            <i-col span="14">{{file.name}}</i-col>
            <i-col span="5">{{(file.size / 1024).toFixed(2)}} KB</i-col>
            <i-col span="5">
              <a class="delete" @click="handleRemove(file)">删除</a>
            </i-col>
            <i-col span="24">
              <hr />
            </i-col>
          </Row>
        </div>
        <div slot="footer">
            <i-button type="primary" :disabled="reason.length<1" @click="handleSubmit"
            :loading="loadingSubmit">确认</i-button>
        </div>
    </Modal>

    <!-- 显示上传进度条 -->
    <Modal
      v-model="beginUpload"
      width="400"
      :styles="{top: '250px'}"
      :mask-closable="false"
      :closable="false"
      footer-hide
    >
      <h3 class="text-center">上传文件中..</h3>
      <Progress :percent="percentage" :stroke-width="20" status="active" text-inside></Progress>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'
import { getSubProcessList } from '@/api/approval/processconfig'
import { getProcessTypeByVersionId } from '@/api/scp/contract'

export default {
  props: {
    id: [String, Number]
  },
  data () {
    return {
      reason: '',
      showModal: false,
      percentage: 0, // 文件上传进度
      beginUpload: false, // 开始执行上传
      fileList: [], // 文件上传
      subs: [],
      sub: null,
      loadingSubmit: false
    }
  },
  methods: {
    initPage () {
      this.showModal = true
      this.initSubProcessList()
    },
    initSubProcessList () {
      getProcessTypeByVersionId({ versionId: this.id, processTypeFeature: 1 }).then(res => {
        getSubProcessList({ processType: res }).then((data) => {
          if (data && data.length > 0) {
            this.subs = data
            this.sub = this.subs.length ? this.subs[0].subProcessType : ''
          } else {
            this.subs = []
          }
        })
      })
      // getSubProcessList({ processType: 23 }).then((data) => {
      //   if (data && data.length > 0) {
      //     this.subs = data
      //     this.sub = this.subs.length ? this.subs[0].subProcessType : ''
      //   } else {
      //     this.subs = []
      //   }
      // })
    },
    handleSubmit () {
      //
      // this.$emit('on-clicked')
      // 开始批量执行上传操作
      this.loadingSubmit = true
      const uploadData = new FormData()
      const that = this
      uploadData.append('bid', this.id)
      uploadData.append('invalidMark', this.reason)
      uploadData.append('subProcessType', this.sub)
      this.fileList.forEach(function (file, index) {
        uploadData.append('files', file)
        that.beginUpload = true
        that.percentage = 0
      })

      axios.post(process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contract/invalidcontractv2',
        uploadData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'ooh-auth': getStorage('ooh-token')
          },
          withCredentials: true,
          onUploadProgress (progress) {
            // 处理上传文件进度条数据
            that.percentage = Math.round(
              (progress.loaded / progress.total) * 100
            )
          }
        })
        .then((res) => {
          that.beginUpload = false
          if (res.data.errcode === 0) {
            this.showModal = false
            this.$Notice.success({ title: '操作成功' })
            this.$emit('on-success')
          } else {
            this.$Notice.error({ desc: res.data.errmsg })
          }

          this.loadingSubmit = false
        }).catch(error => {
          this.$Notice.error({ desc: error })
        })
    },
    handleBeforeUploadFile (file) {
      this.fileList.push(file)
      return false
    },
    handleRemove (file) {
      this.fileList.splice(this.fileList.indexOf(file), 1)
    }
  }

}
</script>
