<template>
  <div>
    <Row>
      <i-col span="24" class="text-right">
        <i-button type="primary" class="m-r-5" size="small"  @click="showHistory=true">审批记录</i-button>
        <i-button type="primary" class="m-r-5" size="small"  @click="handleDownload">下载合同</i-button>
        <i-button type="primary" class="m-r-5" v-if="showAppovalButton" size="small" @click="handleDownloadApproval">下载合同审批表</i-button>
        <i-button type="error" class="m-r-5" size="small"  @click="handleInvalid" v-if="nullifiable">作废</i-button>
        <i-button type="error" class="m-r-5" size="small"  @click="handleChangeCode">更改编号</i-button>
      </i-col>
    </Row>

    <contract-detail ref="contractDetail" :id="id"  :versionId="id" @on-update="handleContractUpdate"></contract-detail>
    <invalid-component ref="invalidComponent" :id="id"  @on-success="handleInvalidSuccess"></invalid-component>
    <Modal v-model="showHistory" :width="800" footer-hide>
      <history :relateId="id" :type="approvalType" v-if="showHistory"></history>
    </Modal>

  </div>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'
import { changeContractCode } from '@/api/scp/contract'

import History from '@/components/approval/History'
import InvalidComponent from '@/components/contract/contractDetail/detail/InvalidComponent'
import ContractDetail from '@/components/contract/contractDetail/Contract'

export default {
  props: {
    id: Number
  },
  components: {
    History, ContractDetail, InvalidComponent
  },
  data () {
    return {
      showHistory: false,
      historyShown: false,
      approvalType: 3,
      contract: {}
    }
  },
  methods: {
    handleInvalid () {
      this.$refs.invalidComponent.initPage()
    },
    handleInvalidSuccess () {
      this.$refs.contractDetail.getDetail()
    },
    handleDownload () {
      const filename = '广告发布合同(' + this.contract.code + ')' + '.doc'

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contract/download', { contractId: this.id }, filename)
    },
    handleDownloadApproval () {
      const filename = '合同审批表(' + this.contract.code + ')' + '.doc'

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contract/downloadapprovalform', { contractId: this.id }, filename)
    },
    handleContractUpdate (contract) {
      this.contract = contract
      this.approvalType = contract.public ? 9 : 3
    },
    handleChangeCode () {
      let newCode = this.contract.code
      this.$Modal.confirm({
        title: '更改合同的编号',
        render: h => {
          return h('Input', {
            props: {
              value: newCode
            },
            on: {
              'on-change': event => {
                newCode = event.target.value
              }
            }

          })
        },
        onOk: () => {
          changeContractCode({ contractId: this.id, contractCode: newCode }).then(res => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '更改编号成功' })
              window.location.reload()
            }
          })
        }
      })
    }
  },
  computed: {
    nullifiable: function () {
      // let startDate = new Date(this.contract.startDate)
      return this.contract.status !== -1
    },
    showAppovalButton () {
      // 只有北京显示此按钮
      return this.$store.getters.token.userInfo.publisherId === 18
    }
  }
}
</script>
